import React from "react";
import { motion, cubicBezier } from "framer-motion";
import { Link } from "react-router-dom";
//components
import Header from "../components/header";
import Footer from "../components/footer";
import Product from "../shop/product";
//import Action from "../components/action";
//Images
import heroImg from "../assets/menu-img-02.jpg";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
import { ReactComponent as CreditCard01 } from "../assets/cart-01.svg";
import { ReactComponent as CreditCard02 } from "../assets/cart-02.svg";
import { ReactComponent as CreditCard03 } from "../assets/cart-03.svg";
import { ReactComponent as CreditCard04 } from "../assets/cart-04.svg";

//Default transition
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const Menu = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="animate"
        exit={{
          opacity: 0,
          transition: {
            duration: 0.6,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        className="content has-bottom-border"
      >
        <div className="hero-section">
          <motion.span
            initial={{
              left: 0,
              opacity: 0,
            }}
            animate={{
              left: 60,
              opacity: 1,
              transition: { delay: 0.9, ...transition },
            }}
            className="hero-back"
          >
            <Link to="/">
              <ArrowLeft />
            </Link>
          </motion.span>
          <motion.h2
            initial={{
              y: 30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.8, ...transition },
            }}
            className="hero-title"
          >
            Јеловник
          </motion.h2>
          <motion.div
            initial={{
              width: "calc(100% - 120px)",
              height: "calc(100vh - 360px)",
            }}
            animate={{
              width: "100%",
              height: window.innerWidth > 1440 ? 500 : 350,
              transition: { delay: 0.2, ...transition },
            }}
            className="hero-img-holder"
          >
            <motion.img
              initial={{
                opacity: 0.4,
                scale: 1.05,
              }}
              animate={{
                transition: { delay: 0.2, ...transition },
              }}
              src={heroImg}
              alt="Идзуо - о нама"
            />
          </motion.div>
        </div>

        <div className="content-in">

            <Product />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
            >
              <h4>Цена доставе</h4>
              <p className="p-2">
                Цена доставе <span className="white">100 динара</span><br/>
                Цена доставе после 22:00 <span className="white">150 динара</span><br/>
                Минимални рачун без доставе <span className="white">600 динара</span>
              </p>
            </motion.div>
            <br />
            <br />
            <br />
            <br />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
              className="cards"
            >
              <h4>Плаћање картицом</h4>
              <p className="p-2">
                Платите вашу поруџбину
                <span className="white"> кредитном картицом</span> при достави
                на лицу места.
              </p>
              <br />
              <br />
              <div className="flex space-between">
                <CreditCard01 />
                <CreditCard02 />
                <CreditCard03 />
                <CreditCard04 />
              </div>
            </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Menu;
