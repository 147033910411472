export const porkData = [
    {
        id: 101,
        name: "Димљена буткица у тортиљи",
        subtitle: "Тортиља",
        desc: "160г димљене буткице, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/pork/dimljena-butkica.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 102,
        name: "Димљена буткица у лепињи",
        subtitle: "Тањир",
        desc: "160г димљене буткице, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/vide-bg-img.jpg",
        cart: "Додајте у корпу",
    },
    {
        id: 103,
        name: "Цепкано прасе у лепињи",
        subtitle: "Лепиња",
        desc: "150г свињског меса, фета сир, микс салата, сосеви",
        grams: "",
        price: "510",
        image: "https://idzuo.com/menuImages/pork/cepkano_prase_u_lepinji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 104,
        name: "Цепкано прасе на тањиру",
        subtitle: "Тањир",
        desc: "250г свињског меса, 1/2 тортиље, микс салата, сосеви, помфрит",
        grams: "",
        price: "560",
        image: "https://idzuo.com/menuImages/pork/cepkano_prase_na_tanjiru.webp",
        cart: "Додајте у корпу",
    },
  ];
  
  